<template>
  <div>

    <!-- 支付成功详情 -->
    <div class="wrapper">
      <div class="wrap">
        <div class="left">
          <div class="title">兑换成功</div>
          <div class="amount">
            <span class="value">{{ order.payAmount }}</span
            >元
          </div>
          <router-link
            class="btn-detail"
            :to="`/order/detail?orderNo=${order.orderNo}`"
            >查看订单详情</router-link
          >
          <div class="tips">
            <span>我们不会以任何理由要求您提供银行卡信息或支付额外费用</span>
            <span>请谨防钓鱼链接或诈骗电话。</span>
          </div>
        </div>
        <div class="right">
          <div class="detail-item">
            <div class="name">订单编号：</div>
            <div class="value">{{ order.orderNo }}</div>
          </div>
          <div class="detail-item">
            <div class="name">收货信息：</div>
            <div class="value">
              <span>{{ order.consignee }} {{ order.phone }}</span>
              <span
                >{{ order.province }} {{ order.city }} {{ order.district }}
                {{ order.address }}</span
              >
            </div>
          </div>
          <!-- <div class="detail-item">
            <div class="name">商品名称：</div>
            <div class="value">{{ order.goodsList }}</div>
          </div> -->
          <!-- <div class="qrcode-wrap">
            <img
              class="qrcode"
              src="https://c1.mifile.cn/f/i/15/pay/app-code.png"
              mode="aspectFit"
            />
            <div class="tips">通过商城app随时跟踪订单</div>
          </div> -->
        </div>
      </div>
    </div>

    <!-- 商品推荐 -->
    <recommend title="为你推荐"></recommend>
  </div>
</template>

<script>
import recommend from "@/components/goods/recommend.vue";

export default {
  components: {
    recommend,
  },
  data() {
    return {
      order: {},
    };
  },
  mounted() {
    var orderNo = this.$route.query.orderNo;
    this.findBaseDetails(orderNo);
  },
  methods: {
    findBaseDetails(orderNo) {
      this.order = {
		orderNo:orderNo, 
        consignee: "ss",
        phone: "12345678910",
        province: "湖南省",
        city: "长沙市",
        district: "岳麓区",
        address: "中南大学科技园研发总部6栋203",
        payAmount: 99.8,
      };
      // uni.showLoading({
      // 	title: '加载中...',
      // 	mask: true
      // });
      // this.$util.request('/order/pay/details', {
      // 	orderNo: orderNo
      // }, (res) => {
      // 	uni.hideLoading();

      // 	if (res.state == 'ok' && res.order.status == 2) {
      // 		this.order = res.order;
      // 	} else {
      // 		uni.redirectTo({
      // 			url: '/pages/index'
      // 		});
      // 	}
      // }, true);
    },
  },
};
</script>

<style lang="scss">
.wrapper {
  background-color: $bg-color-grey;
  padding: 40px 0 60px;

  .wrap {
    width: $page-width;
    margin: 0 auto;
    display: flex;
    height: 400px;

    .left {
      width: 506px;
      background-color: $color-success;
      text-align: center;

      .title {
        font-size: 48px;
        color: #fff;
        line-height: 1;
        margin-top: 70px;
        margin-bottom: 10px;
      }

      .amount {
        margin: 20px 0 30px;
        color: #ffff00;

        .value {
          font-size: 24px;
        }
      }

      .btn-detail {
        margin: 0 auto 42px;
        border: 1px solid #fff;
        background: transparent;
        color: #fff;
        width: 158px;
        height: 38px;
        line-height: 38px;
       	text-align: center;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          opacity: 0.8;
        }
      }

      .tips {
        font-size: 12px;
        // color: $span-color;

        span {
          display: block;
        }
      }
    }

    .right {
      flex: 1;
      background-color: $bg-color;
      padding: 50px 60px;
      position: relative;

      .detail-item {
        line-height: 24px;
        margin-bottom: 8px;
        display: flex;

        .name {
          color: #b0b0b0;
        }

        .value {
          max-width: 420px;
          max-height: 240px;
          overflow: hidden;
          color: #757575;

          span {
            display: block;
          }
        }
      }

      .qrcode-wrap {
        width: 94px;
        position: absolute;
        right: 40px;
        bottom: 25px;
        text-align: center;

        .qrcode {
          width: 86px;
          height: 86px;
        }

        .tips {
          margin-top: 5px;
          font-size: 12px;
          // color: $span-color;
        }
      }
    }
  }
}
</style>
